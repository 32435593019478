<template>
  <el-dialog
    v-el-drag-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :title="$l('repair.create','创建维修工单') "
    width="530px"
    top="3vh"
    :modal="false"
    @close="$reset('form')">
    <el-form
      ref="form"
      v-loading="contentLoading"
      :model="model"
      class="form"
      label-width="auto">
      <el-form-item :label="$l('elevator','电梯')" prop="elevatorId" :rules="$rule.notNull">
        <vm-select-input
          :value="model.elevatorName"
          :placeholder="$l('elevator.select','选择需要报修的电梯')"
          @select="$refs.elevatorSelect.open()"></vm-select-input>
      </el-form-item>

      <el-form-item :label="$l('elevator.worker','维保人员')">
        <el-tag v-if="model.worker1Name">{{model.worker1Name}}</el-tag>
        <el-tag v-if="model.worker2Name">{{model.worker2Name}}</el-tag>
      </el-form-item>

      <el-form-item :label="$l('repair.reporterName','报修人姓名')" prop="reporterName" :rules="$rule.notNull">
        <el-input v-model.trim="model.reporterName" clearable></el-input>
      </el-form-item>

      <el-form-item :label="$l('repair.reporterPhone','报修人电话')" prop="reporterPhone" :rules="$rule.notNull">
        <el-input v-model.trim="model.reporterPhone" clearable></el-input>
      </el-form-item>
     
      <el-form-item label="报修日期" prop="reportedTime">
        <el-date-picker
          v-model="model.reportedTime"
          type="datetime"
          :placeholder="$l('common.select','选择报修日期')"
          value-format="yyyy-MM-dd HH:mm:ss">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="报修备注" prop="reportRemark" :rules="$rule.notNull">
        <el-input v-model="model.reportRemark" type="textarea" :rows="2" :placeholder="$l('common.enter','请输入')"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="dialogVisible=false">{{$l("common.cancel", "取消")}}</el-button>
      <el-button :loading="submitLoading" type="primary" @click="handleSubmit">{{$l("common.save", "保存")}}</el-button>
    </span>
    <elevator-select ref="elevatorSelect" @select="elevatorSelect"></elevator-select>
  </el-dialog>
</template>
<script>
  import ElevatorSelect from "@/views/elevator/ElevatorSelect";

  export default {
    components: {ElevatorSelect},
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        model: {
          source: "customer_service",
          elevatorId: "",
          elevatorName:"",
          reporterName: "",
          reporterPhone: "",
          reportedTime: "",
          reportRemark: "",
        },
      };
    },
    methods: {
      open(id) {
        this.model.id = id;
        this.dialogVisible = true;
      },
      wbTemplateSelect(row) {
        this.model.details = row;
      },
      elevatorSelect(row) {
        this.model.elevatorId = row.id;
        this.model.elevatorName = row.name;
        this.model.worker1Name = row.wbWorker1Name;
        this.model.worker2Name = row.wbWorker2Name;
      },
      handleSubmit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true;
            this.$http
              .save("repair/report", this.model)
              .then(() => {
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$emit("save-success");
                this.$message.success("保存成功");
              })
              .catch(() => {
                this.submitLoading = false;
              });
          }
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
.tip {
  margin-top: -8px;
  margin-bottom: 20px;
}
</style>
