<template>
  <el-dialog
    v-el-drag-dialog
    :visible.sync="dialogVisible"
    title="维修工单详情"
    top="2vh">
    <el-descriptions class="gr-descriptions" border>
      <el-descriptions-item>
        <template slot="label"><i class="el-icon-office-building"></i>工单编号</template>
        {{header.code}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"><i class="el-icon-office-building"></i>工单状态</template>
        {{header.statusDesc}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"><i class="el-icon-office-building"></i>维修人员</template>
        {{header.repairmanName}}
      </el-descriptions-item>
    </el-descriptions>
    <el-tabs v-model="currentCard" type="card">
      <el-tab-pane label="报修" name="report">
        <el-descriptions :column="columnCountOne" class="gr-descriptions" border>
          <el-descriptions-item>
            <template slot="label"><i class="el-icon-office-building"></i> 报修人员</template>
            {{report.reporterName}}
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label"><i class="el-icon-office-building"></i> 报修电话</template>
            {{report.reporterPhone}}
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label"><i class="el-icon-office-building"></i> 报修时间</template>
            {{report.reportedTime}}
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label"><i class="el-icon-location-outline"></i> 报修备注</template>
            {{report.reportRemark}}
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label"><i class="el-icon-location-outline"></i> 核实人员</template>
            {{report.verifierName}}
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label"><i class="el-icon-location-outline"></i> 核实时间</template>
            {{report.verifiedTime}}
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label"><i class="el-icon-location-outline"></i> 核实备注</template>
            {{report.verificationRemark}}
          </el-descriptions-item>
        </el-descriptions>
      </el-tab-pane>
      <el-tab-pane label="故障">
        <el-card v-for="item in fault" :key="item.id" shadow="always" class="gr-fault-card">
          <el-descriptions :column="columnCountOne" :title="item.faultCode">
            <el-descriptions-item label="首次故障时间">{{item.firstOccurredTime}}</el-descriptions-item>
            <el-descriptions-item label="最近故障时间">{{item.lastOccurredTime}}</el-descriptions-item>
            <el-descriptions-item label="故障是否解除">{{item.removed?"是":"否"}}</el-descriptions-item>
            <el-descriptions-item label="最近解除时间">{{item.lastRemovedTime}}</el-descriptions-item>
          </el-descriptions>
        </el-card>
        <vm-table
          ref="faultLogTable"
          :filter.sync="faultFilter"
          :url="'repair/'+repairOrderId+'/fault-log'"
          @clear="faultFilter.startTime=report.reportedTime">
          <template slot="adSearch">
            <vm-search label="故障代码">
              <el-input v-model.trim="faultFilter.faultCode" clearable></el-input>
            </vm-search>
            <vm-search label="类型">
              <vm-dict-select v-model="faultFilter.type" type="faultType"></vm-dict-select>
            </vm-search>
            <vm-search label="开始时间">
              <el-date-picker
                v-model="faultFilter.startTime"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-value="faultFilter.startTime"
                clearable>
              </el-date-picker>
            </vm-search>
            <vm-search label="结束时间">
              <el-date-picker
                v-model="faultFilter.endTime"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss"
                clearable>
              </el-date-picker>
            </vm-search>
          </template>
          <el-table-column
            label="时间">
            <template slot-scope="scope">
              {{dayjs(scope.row.faultTime).format('YYYY-MM-DD HH:mm:ss')}}
            </template>
          </el-table-column>

          <el-table-column
            prop="type"
            label="类型">
          </el-table-column>

          <el-table-column
            prop="faultCode"
            label="故障码">
          </el-table-column>

          <el-table-column
            prop="faultName"
            label="故障名称">
          </el-table-column>

          <el-table-column
            prop="faultLevel"
            label="故障等级">
          </el-table-column>

          <el-table-column
            prop="time"
            label="数据保存时间">
          </el-table-column>
        </vm-table>
      </el-tab-pane>
      <el-tab-pane label="电梯">
        <el-descriptions :column="columnCountTwo" title="基本信息" class="gr-descriptions" border>
          <el-descriptions-item>
            <template slot="label"><i class="el-icon-office-building"></i> 使用单位</template>
            {{elevator.useOrgName}}
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label"><i class="el-icon-office-building"></i> 维保项目</template>
            {{elevator.projectName}}
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label"><i class="el-icon-location-outline"></i> 名称/位置</template>
            {{elevator.name}}
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label"><i class="el-icon-location-outline"></i> 电梯状态</template>
            {{elevator.statusDesc}}
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label"><i class="el-icon-location-outline"></i> 使用场所</template>
            {{elevator.usePlace}}
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label"><i class="el-icon-location-outline"></i> 使用证号</template>
            {{elevator.useCode}}
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label"><i class="el-icon-location-outline"></i> 注册代码</template>
            {{elevator.regCode}}
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label"><i class="el-icon-location-outline"></i> 救援码/位置码</template>
            {{elevator.rescueCode}}
          </el-descriptions-item>
        </el-descriptions>

        <el-descriptions :column="columnCountTwo" title="参数信息" class="gr-descriptions" border>
          <el-descriptions-item>
            <template slot="label"><i class="el-icon-office-building"></i> 类别</template>
            {{elevator.category}}
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label"><i class="el-icon-office-building"></i> 品种</template>
            {{elevator.typeDesc}}
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label"><i class="el-icon-location-outline"></i> 品牌</template>
            {{elevator.brand}}
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label"><i class="el-icon-location-outline"></i> 型号</template>
            {{elevator.model}}
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label"><i class="el-icon-location-outline"></i> 控制系统</template>
            {{elevator.controlSystem}}
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label"><i class="el-icon-location-outline"></i> 出厂编号</template>
            {{elevator.factoryCode}}
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label"><i class="el-icon-location-outline"></i> 制造单位</template>
            {{elevator.manufacturingName}}
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label"><i class="el-icon-location-outline"></i> {{$l('elevator.location','出厂日期')}}</template>
            {{elevator.elevatorName}}
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label"><i class="el-icon-location-outline"></i> {{$l('elevator.location','安装单位')}}</template>
            {{elevator.elevatorName}}
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label"><i class="el-icon-location-outline"></i> {{$l('elevator.location','安装日期')}}</template>
            {{elevator.elevatorName}}
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label"><i class="el-icon-location-outline"></i> {{$l('elevator.location','层/站/门')}}</template>
            {{elevator.floor}}/{{elevator.station}}/{{elevator.door}}
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label"><i class="el-icon-location-outline"></i> {{$l('elevator.location','起升高度')}}</template>
            {{elevator.height}}
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label"><i class="el-icon-location-outline"></i> {{$l('elevator.location','额定速度')}}</template>
            {{elevator.speed}}
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label"><i class="el-icon-location-outline"></i> {{$l('elevator.location','额定载重量')}}</template>
            {{elevator.loads}}
          </el-descriptions-item>
        </el-descriptions>

        <el-descriptions :column="columnCountTwo" title="遥监设备信息" class="gr-descriptions" border>
          <el-descriptions-item>
            <template slot="label"><i class="el-icon-office-building"></i> 设备码</template>
            {{elevator.deviceNo}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"><i class="el-icon-office-building"></i> 最近心跳时间</template>
            {{elevator.deviceHeartbeatTime}}
          </el-descriptions-item>
        </el-descriptions>
      </el-tab-pane>
      <el-tab-pane label="地图">
        <vm-point-map ref="map" :editable="false" :lat.sync="map.lat" :lng.sync="map.lng"></vm-point-map>
      </el-tab-pane>
      <el-tab-pane label="方案">
        <el-collapse v-if="plan&&plan[0]" v-model="plan[0].id">
          <el-collapse-item v-for="item in plan" :key="item.id" :title="item.planTime" :name="item.id">
            <el-descriptions :column="columnCountOne" class="gr-descriptions" border>
              <el-descriptions-item>
                <template slot="label"><i class="el-icon-office-building"></i> 故障现象</template>
                {{item.projectName}}
              </el-descriptions-item>

              <el-descriptions-item>
                <template slot="label"><i class="el-icon-office-building"></i> 原因分析</template>
                {{item.projectName}}
              </el-descriptions-item>

              <el-descriptions-item>
                <template slot="label"><i class="el-icon-office-building"></i> 维修方案</template>
                {{item.projectName}}
              </el-descriptions-item>

              <el-descriptions-item>
                <template slot="label"><i class="el-icon-office-building"></i> 维修结果</template>
                {{item.projectName}}
              </el-descriptions-item>

              <el-descriptions-item>
                <template slot="label"><i class="el-icon-office-building"></i> 客户确认</template>
                {{item.projectName}}
              </el-descriptions-item>
            </el-descriptions>
          </el-collapse-item>
        </el-collapse>
      </el-tab-pane>
      <el-tab-pane label="回访">
        <el-descriptions :column="columnCountOne" class="gr-descriptions" border>
          <el-descriptions-item>
            <template slot="label"><i class="el-icon-office-building"></i> 回访方式</template>
            {{returnVisit.projectName}}
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label"><i class="el-icon-office-building"></i> 客户姓名</template>
            {{returnVisit.visitedCustomerName}}
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label"><i class="el-icon-office-building"></i> 客服姓名</template>
            {{returnVisit.visitedCsrName}}
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label"><i class="el-icon-office-building"></i> 回访时间</template>
            {{returnVisit.visitedTime}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"><i class="el-icon-office-building"></i> 服务态度评分</template>
            <el-rate v-if="returnVisit.visitedMaintenanceQualityScore" v-model="returnVisit.visitedMaintenanceQualityScore" disabled show-score></el-rate>
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label"><i class="el-icon-office-building"></i> 维保质量评分</template>
            <el-rate v-if="returnVisit.visitedMaintenanceQualityScore" v-model="returnVisit.visitedMaintenanceQualityScore" disabled show-score></el-rate>
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label"><i class="el-icon-location-outline"></i>回访客户意见</template>
            {{returnVisit.visitedCustomerComment}}
          </el-descriptions-item>
        </el-descriptions>
      </el-tab-pane>
      <el-tab-pane label="日志">
        <el-timeline v-if="log&&log[0]" class="gr-timeline">
          <el-timeline-item
            v-for="item in log"
            :key="item.id"
            :timestamp="item.operatedTime">
            {{item.operatorName}} - {{item.operateTypeDesc}} {{item.content?("-"+item.content):""}}
          </el-timeline-item>
        </el-timeline>
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
</template>
<script>
  import VmPointMap from "@/components/VmPointMap";
  export default {
    components: {VmPointMap},
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        columnCountOne:1,
        columnCountTwo:2,
        defaultCard:"report",
        currentCard:null,
        repairOrderId:null,
        elevatorId:null,
        header:{
          code: null,
          statusDesc: null,
          repairmanName:null,
        },
        report:{
          reporterName: null,
          reporterPhone: null,
          reportedTime: null,
          reportRemark: null,
          verifierName: null,
          verifiedTime:null,
          verificationRemark:null,
        },
        fault:[],
        faultFilter:{
          faultCode:null,
          type:null,
          startTime:null,
          endTime:null,
        },
        faultLog:[],
        elevator:{
          useOrgName:null,
          projectName:null,
          name:null,
          statusDesc:null,
          usePlace:null,
          useCode:null,
          regCode:null,
          rescueCode:null,

          category:null,
          typeDesc:null,
          brand:null,
          model:null,
          controlSystem:null,
          factoryCode:null,
          manufacturingName:null,
          factoryDate:null,
          installationOrg:null,
          installationDate:null,
          floor:null,
          station:null,
          door:null,
          height:null,
          speed:null,
          loads:null,
        },
        map:{
          lat:31.230943,
          lng:121.472987,
        },
        plan:[],
        returnVisit:{
          visitedCsrName:null,
          visitedTime:null,
          customerComment:null,
          serviceAttitudeScore:null,
          maintenanceQualityScore:null,
        },
        log:[],
      };
    },
    methods: {
      open(id) {
        this.repairOrderId = id;
        this.currentCard = this.defaultCard;
        this.dialogVisible = true;
        this.getRepairData();
        this.getFaultData();
        this.getLogData();
      },
      getRepairData() {
        this.contentLoading = true;
        this.$http.get(`repair/${this.repairOrderId}`).then(data => {
          this.contentLoading = false;
          this.header = data;
          this.report = data;
          this.returnVisit = data;
          return data;
        }).then(data => {
          this.getElevatorData(data);

          this.getFaultLogData();
        });
      },
      getElevatorData(fromData) {
        this.contentLoading = true;
        this.$http.get(`elevators/${fromData.elevatorId}`).then(data => {
          this.contentLoading = false;
          this.elevator = data;
          this.elevatorId = fromData.elevatorId;
          return data;
        }).then(data => {
          this.getMapData(data);
        });
      },
      getMapData(data) {
        this.map=data;
        this.$nextTick(() => {
          this.$refs.map.initMap();
        });
      },
      getFaultData() {
        this.contentLoading = true;
        this.$http.get(`repair/${this.repairOrderId}/fault`).then(data => {
          this.contentLoading = false;
          this.fault = data;
        });
      },
      getFaultLogData() {
        this.faultFilter.startTime = this.report.reportedTime;
        this.faultFilter.endTime = (this.report.repaired?this.report.repairedTime:(this.report.cancelled?this.report.cancelledTime:null));
        this.$nextTick(() => {
          this.$refs.faultLogTable.getList(1);
        });
      },
      getLogData() {
        this.contentLoading = true;
        this.$http.get(`repair/${this.repairOrderId}/log`).then(data => {
          this.contentLoading = false;
          this.log = data;
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
.gr-descriptions{
  margin-bottom: 10px;
}
.gr-fault-card{
  width: 260px;
  display: inline-block;
  margin: 10px;
}
.gr-timeline{
  margin: 10px;
}
</style>
