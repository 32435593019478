var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{directives:[{name:"el-drag-dialog",rawName:"v-el-drag-dialog"}],attrs:{"visible":_vm.dialogVisible,"close-on-click-modal":false,"title":"指派维修人员","top":"3vh","modal":false},on:{"update:visible":function($event){_vm.dialogVisible=$event},"close":function($event){return _vm.$reset('form')}}},[[_c('el-transfer',{staticStyle:{"text-align":"left","display":"inline-block"},attrs:{"filterable":"","props":{
        key: 'name',
        label: 'name',
      },"titles":['可指派维修人员', '当前维修人员'],"button-texts":['撤回', '指派'],"format":{
        noChecked: '${total}',
        hasChecked: '${checked}/${total}'
      },"data":_vm.allRepairman},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var option = ref.option;
return _c('span',{},[_vm._v(" "+_vm._s(option.name)+" ")])}}]),model:{value:(_vm.currentRepairmanKey),callback:function ($$v) {_vm.currentRepairmanKey=$$v},expression:"currentRepairmanKey"}})],_c('span',{attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.dialogVisible=false}}},[_vm._v(_vm._s(_vm.$l("common.cancel", "取消")))]),_c('el-button',{attrs:{"loading":_vm.submitLoading,"type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v(_vm._s(_vm.$l("common.save", "保存")))])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }