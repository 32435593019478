<template>
  <el-dialog
    v-el-drag-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    title="回访"
    top="3vh"
    :modal="false"
    @close="$reset('form')">
    <el-form
      ref="form"
      v-loading="contentLoading"
      :model="model"
      class="form"
      label-width="auto">
      <el-form-item label="使用单位" prop="useOrgName">
        <el-input v-model="useOrgName" type="text" readonly></el-input>
      </el-form-item>
      <el-form-item label="回访客户姓名" prop="visitedCustomerName">
        <el-input v-model="model.visitedCustomerName" type="text"></el-input>
      </el-form-item>
      <el-form-item label="回访时间" prop="visitedTime">
        <el-date-picker
          v-model="model.visitedTime"
          type="datetime"
          placeholder="选择回访日期,不填则默认为提交时间"
          value-format="yyyy-MM-dd HH:mm:ss">
        </el-date-picker>
      </el-form-item>

      <el-form-item label="服务态度评分" prop="visitedServiceAttitudeScore">
        <el-rate v-model="model.visitedServiceAttitudeScore" show-score></el-rate>
      </el-form-item>

      <el-form-item label="维保质量评分" prop="visitedMaintenanceQualityScore">
        <el-rate v-model="model.visitedMaintenanceQualityScore" show-score></el-rate>
      </el-form-item>

      <el-form-item label="回访客户意见" prop="visitedCustomerComment">
        <el-input v-model="model.visitedCustomerComment" :row="2" type="textarea"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="dialogVisible=false">{{$l("common.cancel", "取消")}}</el-button>
      <el-button :loading="submitLoading" type="primary" @click="handleSubmit">{{$l("common.save", "保存")}}</el-button>
    </span>
  </el-dialog>
</template>
<script>
  export default {
    components: {},
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        useOrgName:null,
        repairOrderId:null,
        model: {
          visitedCustomerName: null,
          visitedTime: null,
          visitedServiceAttitudeScore:null,
          visitedMaintenanceQualityScore:null,
          visitedCustomerComment:null,
        },
      };
    },
    methods: {
      open(id,useOrgName) {
        this.dialogVisible = true;
        this.repairOrderId = id;
        this.useOrgName = useOrgName;
      },
      handleSubmit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true;
            this.$http
              .post("repair/"+this.repairOrderId+"/return-visit", this.model)
              .then(() => {
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$emit("save-success");
                this.$message.success("维修工单的回访提交成功");
              }).catch(() => {
                this.submitLoading = false;
              });
          }
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
.tip {
  margin-top: -8px;
  margin-bottom: 20px;
}
</style>
