<template>
  <div>
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="repair/report/tenant/page">
      <el-button slot="toolbar" type="primary" size="mini" @click="$refs.createPage.open()">维修报修</el-button>
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search label="省市地区">
            <vm-district-select
              :province-code.sync="filter.provinceCode"
              :city-code.sync="filter.cityCode"
              :area-code.sync="filter.areaCode">
            </vm-district-select>
          </vm-search>
          <vm-search label="使用单位">
            <use-org-select :use-org-id.sync="filter.useOrgId" clearable></use-org-select>
          </vm-search>
          <vm-search label="维保项目">
            <maint-project-select :maint-project-id.sync="filter.maintProjectId" clearable></maint-project-select>
          </vm-search>
          <vm-search label="电梯名称/位置">
            <el-input v-model.trim="filter.elevatorName" clearable></el-input>
          </vm-search>
          <vm-search label="工单编号">
            <el-input v-model.trim="filter.code" clearable></el-input>
          </vm-search>        
          <vm-search label="工单状态">
            <vm-dict-select v-model="filter.status" type="repairOrderStatus"></vm-dict-select>
          </vm-search>
          <vm-search label="创建人">
            <el-input v-model.trim="filter.createBy" clearable></el-input>
          </vm-search>
          <vm-search label="报修人/电话">
            <el-input v-model.trim="filter.reporterNameOrPhone" clearable></el-input>
          </vm-search>
          <vm-search label="维修人员">
            <el-input v-model.trim="filter.repairerName" clearable></el-input>
          </vm-search>
          <vm-search label="创建时间起始">
            <el-date-picker v-model.trim="filter.createTimeStart" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" clearable></el-date-picker>
          </vm-search>
          <vm-search label="创建时间截止">
            <el-date-picker v-model.trim="filter.createTimeEnd" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" clearable></el-date-picker>
          </vm-search>
        </div>
      </template>
      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column prop="code" label="工单编号" align="center" width="135"></el-table-column>
      <el-table-column prop="useOrgName" label="使用单位" align="center"></el-table-column>
      <el-table-column prop="maintProjectName" label="项目名称" align="center"></el-table-column>
      <el-table-column prop="districtName" label="省市地区" align="center"></el-table-column>
      <el-table-column prop="elevatorName" label="电梯名称/位置" align="center"></el-table-column>
      <el-table-column prop="statusDesc" label="工单状态" align="center"></el-table-column>
      <el-table-column prop="createBy" label="创建人" align="center"></el-table-column>
      <el-table-column prop="reportRemark" label="报修备注" align="center"></el-table-column>
      <el-table-column prop="cancellerName" label="取消人" align="center"></el-table-column>
      <el-table-column prop="cancellationRemark" label="取消备注" align="center"></el-table-column>
      <el-table-column label="报修人/电话" align="center">
        <template slot-scope="scope">
          {{scope.row.reporterName}}/{{scope.row.reporterPhone}}
        </template>
      </el-table-column>
      <el-table-column prop="repairmanName" :label="$l('repair.repairmanName','维修人员')" align="center"></el-table-column>
      <el-table-column prop="createTime" :label="$l('repair.createTime','创建时间')" align="center"></el-table-column>
      <el-table-column
        :label="$l('common.function','操作')"
        fixed="right"
        align="center"
        class-name="vm-table_operate"
        width="180">
        <template slot-scope="scope">
          <el-button type="primary" @click="$refs.detailPage.open(scope.row.id)">详情</el-button>
          <el-dropdown trigger="click">
            <el-button type="primary">
              更多操作<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item icon="el-icon-plus" command="assign" @click.native="$refs.repairmanPage.open(scope.row.id,scope.row.repairmanName)">指派维修人员</el-dropdown-item>
              <el-dropdown-item icon="el-icon-circle-plus" command="verify" @click.native="$refs.verificationPage.open(scope.row.id,scope.row.reportRemark)">情况调查核实</el-dropdown-item>
              <el-dropdown-item icon="el-icon-circle-plus-outline" command="returnVisit" @click.native="$refs.returnVisitPage.open(scope.row.id,scope.row.useOrgName)">回访</el-dropdown-item>
              <el-dropdown-item icon="el-icon-check" command="cancel" @click.native="cancelOrder(scope.row.id)">取消工单</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </vm-table>
    <repair-create ref="createPage" @save-success="getList(-1)"></repair-create>
    <repair-detail ref="detailPage"></repair-detail>
    <repairman-assign ref="repairmanPage" @save-success="getList(-1)"></repairman-assign>
    <repair-verification ref="verificationPage" @save-success="getList(-1)"></repair-verification>
    <repair-return-visit ref="returnVisitPage" @save-success="getList(-1)"></repair-return-visit>
  </div>
</template>
<script>
  import UseOrgSelect from "@/views/customer/UseOrgSelect";
  import MaintProjectSelect from "@/views/project/MaintProjectSelect";
  import RepairCreate from "./RepairCreate";
  import RepairDetail from "./RepairDetail";
  import RepairmanAssign from "./RepairmanAssign";
  import RepairVerification from "./RepairVerification";
  import RepairReturnVisit from "./RepairReturnVisit";

  export default {
    components: {UseOrgSelect,MaintProjectSelect,RepairCreate,RepairDetail,RepairmanAssign,RepairVerification,RepairReturnVisit},
    data() {
      return {
        useOrgName:null,
        maintProjectName:null,     
        filter: {
          provinceCode:null,
          cityCode:null,
          areaCode:null,
          useOrgId:null,
          maintProjectId:null,
          elevatorName:null,
          code:null,
          status:null,
          createBy:null,
          reporterNameOrPhone:null,
          repairmanName:null,
          createTimeStart:null,
          createTimeEnd:null,
        },
      };
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      getList(pageNum) {
        this.$refs.vmTable.getList(pageNum);
      },
      cancelOrder(repairOrderId) {
        this.$prompt("此操作将取消维修工单, 请注入备注", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(({value}) => {
          this.$http
            .post("repair/"+repairOrderId+"/cancellation",value)
            .then(() => {
              this.$message.success("维修工单取消成功");
              this.getList(-1);
            }).catch(() => {
              this.$message.error("维修工单取消失败");
            });
        }).catch(() => {
          this.$message.info("取消对维修工单的取消操作");
        });
      },
    },    
  };
</script>
<style lang="scss" scoped>
</style>
