<template>
  <div>
    <el-input
      v-model="internalValue"
      :maxlength="0"      
      :placeholder="disabled?'':placeholder"
      :disabled="disabled"
      :clearable="clearable"
      class="vm-select-input">
      <div v-if="!disabled" slot="append" class="iconWrapper" @click="onSearch">
        <i class="el-icon-search"></i>
      </div>
    </el-input>
    <el-dialog
      v-el-drag-dialog
      append-to-body
      :visible.sync="dialogVisible"
      title="选择使用单位"
      class="small-padding"
      width="1000px"
      :modal="false"
      top="3vh">
      <vm-table
        ref="vmTable"
        :filter.sync="filter"
        url="customers">
        <template slot="adSearch">
          <div class="vm-search">
            <vm-search :label="$l('customer.name','客户名称')">
              <el-input v-model.trim="filter.name" clearable></el-input>
            </vm-search>
            <vm-search :label="$l('user.contactPerson','联系人')">
              <el-input v-model.trim="filter.contactName" clearable></el-input>
            </vm-search>
            <vm-search :label="$l('user.phone','手机号')">
              <el-input v-model.trim="filter.contactPhone" clearable></el-input>
            </vm-search>
            <vm-search :label="$l('address.area','区域')">
              <vm-district-select
                :province-code.sync="filter.provinceCode"
                :city-code.sync="filter.cityCode"
                :area-code.sync="filter.areaCode">
              </vm-district-select>
            </vm-search>
          </div>
        </template>
        <el-table-column align="center" type="index" width="50"></el-table-column>
        <el-table-column prop="name" :label="$l('customer.name','客户名称')"></el-table-column>
        <el-table-column prop="contactName" :label="$l('user.contactPerson','联系人')"></el-table-column>
        <el-table-column prop="contactPhone" :label="$l('user.phone','手机号')" align="center"></el-table-column>
        <el-table-column :label="$l('address.area','区域')" align="center">
          <template slot-scope="scope">
            {{scope.row.province + "-" + scope.row.city + "-" + scope.row.area}}
          </template>
        </el-table-column>
        <el-table-column prop="address" :label="$l('address.address','地址')" align="center"></el-table-column>
        <el-table-column width="70" :label="$l('common.function','操作')" align="center">
          <template slot-scope="scope">
            <el-button type="primary" plain @click="onSelect(scope.row)">选择</el-button>
          </template>
        </el-table-column>
      </vm-table>
    </el-dialog>
  </div>  
</template>

<script>

  export default {
    components: {},
    props: {
      useOrgId: Number,
      clearable: Boolean,
      disabled: Boolean,
      placeholder: {
        type: String,
        default() {
          return this.$l("common.select", "请选择");
        },
      },
    },
    data() {
      return {
        useOrgName:"",
        dialogVisible: false,
        filter: {
          name: "",
          contactName: "",
          contactPhone: "",
          provinceCode: "",
          cityCode: "",
          areaCode: "",
        },
      };
    },
    computed:{
      internalValue: {
        get() {
          return this.useOrgName;
        },
        set(v) {
          this.useOrgName = v;
          this.$emit("update:useOrgId", this.useOrgId);
        },
      },
    },
    watch:{
      useOrgId:function() {
        if(!this.useOrgId) {
          this.useOrgName =null;
        }
      },
    },
    methods: {
      open() {
        this.filter={};
        this.dialogVisible = true;
        this.$nextTick(() => {
          this.$refs.vmTable.getList(1);
        });
      },
      onSearch() {
        this.open();
      },
      onSelect(row) {
        this.dialogVisible = false;
        this.useOrgName = row.name;
        this.$emit("update:useOrgId",row.id);
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
