<template>
  <div>
    <el-input
      v-model="internalValue"
      :maxlength="0"      
      :placeholder="disabled?'':placeholder"
      :disabled="disabled"
      :clearable="clearable"
      class="vm-select-input">
      <div v-if="!disabled" slot="append" class="iconWrapper" @click="onSearch">
        <i class="el-icon-search"></i>
      </div>
    </el-input>
    <el-dialog
      v-el-drag-dialog
      append-to-body
      :visible.sync="dialogVisible"
      title="选择维保项目"
      class="small-padding"
      width="1000px"
      :modal="false"
      top="3vh">
      <vm-table
        ref="vmTable"
        :filter.sync="filter"
        url="projects">
        <template slot="adSearch">
          <div class="vm-search">
            <vm-search :label="$l('project.name','项目名称')">
              <el-input v-model.trim="filter.name" clearable></el-input>
            </vm-search>
            <vm-search label="客户名称">
              <el-input v-model.trim="filter.customerName" clearable></el-input>
            </vm-search>
            <vm-search label="维保经理姓名">
              <el-input v-model.trim="filter.wbManagerName" clearable></el-input>
            </vm-search>
            <vm-search label="项目状态">
              <vm-dict-select v-model="filter.status" type="projectStatus"></vm-dict-select>
            </vm-search>
          </div>
        </template>
        <el-table-column align="center" type="index" width="50"></el-table-column>
        <el-table-column prop="name" :label="$l('project.name','项目名称')"></el-table-column>
        <el-table-column prop="customerName" label="客户名称"></el-table-column>
        <el-table-column prop="wbManagerName" label="维保经理">
          <template slot-scope="scope">
            {{scope.row.wbManagerName + "/" + scope.row.wbManagerPhone}}
          </template>
        </el-table-column>
        <el-table-column prop="statusDesc" label="项目状态"></el-table-column>
        <el-table-column :label="$l('common.function','操作')" align="center" class-name="vm-table_operate">
          <template slot-scope="scope">
            <el-button type="primary" plain @click="onSelect(scope.row)">选择</el-button>
          </template>
        </el-table-column>
      </vm-table>
    </el-dialog>
  </div>  
</template>

<script>

  export default {
    components: {},
    props: {
      maintProjectId: Number,
      clearable: Boolean,
      disabled: Boolean,
      placeholder: {
        type: String,
        default() {
          return this.$l("common.select", "请选择");
        },
      },
    },
    data() {
      return {
        maintProjectName:"",
        dialogVisible: false,
        filter: {
          name: "",
          customerName: "",
          wbManagerName: "",
          status: "",
        },
      };
    },
    computed:{
      internalValue: {
        get() {
          return this.maintProjectName;
        },
        set(v) {
          this.maintProjectName = v;
          this.$emit("update:maintProjectId", this.maintProjectId);
        },
      },
    },
    watch:{
      maintProjectId:function() {
        if(!this.maintProjectId) {
          this.maintProjectName = null;
        }
      },
    },
    methods: {
      open() {
        this.filter={};
        this.dialogVisible = true;
        this.$nextTick(() => {
          this.$refs.vmTable.getList(1);
        });
      },
      onSearch() {
        this.open();
      },
      onSelect(row) {
        this.dialogVisible = false;
        this.maintProjectName = row.name;
        this.$emit("update:maintProjectId",row.id);
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
