<template>
  <el-dialog
    v-el-drag-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    title="指派维修人员"
    top="3vh"
    :modal="false"
    @close="$reset('form')">
    <template>
      <el-transfer
        v-model="currentRepairmanKey"
        style="text-align: left; display: inline-block"
        filterable
        :props="{
          key: 'name',
          label: 'name',
        }"
        :titles="['可指派维修人员', '当前维修人员']"
        :button-texts="['撤回', '指派']"
        :format="{
          noChecked: '${total}',
          hasChecked: '${checked}/${total}'
        }"
        :data="allRepairman">
        <span slot-scope="{option}">
          {{option.name}}
        </span>
      </el-transfer>
    </template>
    <span slot="footer">
      <el-button @click="dialogVisible=false">{{$l("common.cancel", "取消")}}</el-button>
      <el-button :loading="submitLoading" type="primary" @click="handleSubmit">{{$l("common.save", "保存")}}</el-button>
    </span>
  </el-dialog>
</template>
<script>

  export default {
    components: {},
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        repairOrderId:null,
        currentRepairmanKey:[],
        currentRepairman:[],
        allRepairman:[],
      };
    },
    watch:{
      currentRepairmanKey:function() {
        let currentRepairmanKey = this.currentRepairmanKey;
        this.currentRepairman = this.allRepairman.filter(function(item) {
          return  currentRepairmanKey.includes(item.name);
        });
      },
    },
    methods: {
      open(id,repairmanName) {
        this.dialogVisible = true;

        this.repairOrderId = id;
        if(repairmanName) {
          this.currentRepairmanKey = repairmanName.split(",");
        }

        this.getRepairman();
      },
      handleSubmit() {
        this.submitLoading = true;
        this.$http.post("repair/"+this.repairOrderId+"/assignment",this.currentRepairman).then(() => {
          this.submitLoading = false;
          this.dialogVisible = false;
          this.$emit("save-success");
          this.$message.success("指派维修人员成功");
        }).catch(() => {
          this.submitLoading = false;
        });
      },
      getRepairman() {
        this.contentLoading = true;
        this.$http.get("users",{"page":1,pageSize:"1000"}).then(data => {
          this.contentLoading = false;
          if(data && data.records) {
            this.allRepairman = data.records;
          }
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
.tip {
  margin-top: -8px;
  margin-bottom: 20px;
}
</style>
